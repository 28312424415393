import { useIsLaptop } from '../responsive/hooks.ts';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../popover/popover.tsx';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../tooltip/tooltip.tsx';
import * as _TooltipPrimitive from '@radix-ui/react-tooltip';
import * as _PopoverPrimitive from '@radix-ui/react-popover';
import { clsx } from 'clsx';
import { tooltipContent } from '../tooltip/tooltip.css.ts';

interface HintProps
  extends ComponentPropsWithoutRef<
    typeof _TooltipPrimitive.Content & typeof _PopoverPrimitive.Content
  > {
  trigger: ReactNode;
}

export const Hint = forwardRef<
  ElementRef<
    typeof _TooltipPrimitive.Content | typeof _PopoverPrimitive.Content
  >,
  HintProps
>(({ children, trigger, className }, ref) => {
  const isLaptop = useIsLaptop();

  return isLaptop ? (
    <Tooltip delayDuration={100}>
      <TooltipTrigger>{trigger}</TooltipTrigger>
      <TooltipContent className={className} ref={ref}>
        {children}
      </TooltipContent>
    </Tooltip>
  ) : (
    <Popover>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent className={clsx(className, tooltipContent)} ref={ref}>
        {children}
      </PopoverContent>
    </Popover>
  );
});
Hint.displayName = 'Hint';
